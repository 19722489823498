import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NewsLetterComponent from "../components/news-letter";

export default function NewsLetter({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout pageName="news-letter" location={location} title={siteTitle}>
      <SEO title="News Letter" />
      <NewsLetterComponent />
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    imageSharp(fixed: { src: { regex: "/profile-pic.jpg/" } }) {
      id
      original {
        height
        src
        width
      }
    }
  }
`;
